import React from "react"
import { StaticQuery, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import FormCallback from './FormCallback'



const ModalCallback = ({buttonVariant, buttonText, buttonClass, props})  => {
  const [show, setShow] = React.useState(false);
  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  const handleToggle = () => setShow(!show);

  return (
    <StaticQuery
      query={graphql`
        query {
          background: file(relativePath: {eq: "call.jpeg"}) {
            relativePath
            id
            childImageSharp {
              fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => {
        // Set ImageData.
        const imageData = [
          data.background.childImageSharp.fluid,
          `linear-gradient(
            25deg, 
            rgba(128,0,128,0.4),
            rgba(35,65,97,0.9) 60%)`,
        ].reverse()

        return (
          <>
            <Button variant={buttonVariant} onClick={handleToggle} className={buttonClass} >
              {buttonText}
            </Button>
  
            <Modal
              {...props}
              show={show}
              onHide={() => setShow(false)}
              aria-labelledby="callbackModal"
              className="clearfix text-white"
              centered
              >
              <BackgroundImage fluid={imageData}>
                <Modal.Header closeButton>
                  <Modal.Title id="callbackModal">
                    Зворотній дзвінок
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <FormCallback />
                </Modal.Body>
              </BackgroundImage>
            </Modal>
          </>
        )
      }}
    />
  )
}

ModalCallback.defaultProps = {
  buttonText: `Консультація`,
  buttonVariant: `link`,
  buttonClass: ``,
}

export default ModalCallback
import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

import { MdKeyboardArrowRight } from "react-icons/md"

import Card from "react-bootstrap/Card"
import Button from "react-bootstrap/Button"

const ServiceCard = ({
  serviceFluidImage,
  serviceTitle,
  servicePrice,
  serviceValid,
  serviceFrom,
  serviceUpTo,
  serviceTax,
  serviceLink,
  serviceId,
  lang,
}) => (
  <Card className="service-card">
    <Img
      as={Card.Img}
      variant="top"
      className="card-img-top"
      fluid={serviceFluidImage}
      alt={`${serviceTitle}, фото`}
      title={serviceTitle}
      loading="lazy"
    />
    <div className="card-img-overlay d-flex align-items-center p4">
      <div className="card_title_custom">
        <Link to={serviceLink}>{serviceTitle}</Link>
        {[2, 3, 4, 5].includes(serviceId, 0) &&
          (lang === "ru" ? (
            <div className="badge badge-success badge-text mt-2">
              ✓изменения на военное время
            </div>
          ) : (
            <div className="badge badge-success badge-text mt-2">
              ✓нові умови на воєнний стан
            </div>
          ))}
      </div>
    </div>
    <Card.Body className="">
      <ul className="list-group nostyle-list">
        <li className="pb-3">
          <nobr className="card-price-main">{servicePrice} грн</nobr>
        </li>
        {/* {serviceTax > 0 ? (
          <li>
            {lang === "ru" ? "Гос. платёж" : "Державний платіж" }
            <nobr className="attention-color"> + {serviceTax} грн</nobr>
          </li>
        ): ``} */}
        <li>
          {lang === "ru" ? "Срок действия" : "Термін дії"} —{" "}
          <span className="attention-color">{serviceValid}</span>
        </li>
        {lang === "ru" ? (
          <li>
            Оформление
            {serviceFrom > 0 ? ` от ${serviceFrom} ` : ""}
            {serviceUpTo > 0 ? ` до ${serviceUpTo} ` : ""}
            дней
          </li>
        ) : (
          <li>
            Оформлення
            {serviceFrom > 0 ? ` від ${serviceFrom} ` : ""}
            {serviceUpTo > 0 ? ` до ${serviceUpTo} ` : ""}
            днів
          </li>
        )}
        {lang === "ru" ? (
          <li>Оплата после оформления</li>
        ) : (
          <li>Оплата після оформлення</li>
        )}
      </ul>
      <Button
        as={Link}
        variant="outline-brand"
        to={serviceLink}
        className="mt-4"
      >
        <MdKeyboardArrowRight />
        {lang === "ru" ? ` Узнать больше` : ` Дізнатися більше`}
      </Button>
    </Card.Body>
  </Card>
)

ServiceCard.defaultProps = {
  serviceFluidImage: ``,
  serviceTitle: `Ліцензія для роботи в таксі`,
  servicePrice: `1500`,
  serviceValid: `безстрокова`,
  serviceFrom: `3`,
  serviceUpTo: `10`,
  serviceTax: `2270`,
  serviceLink: `/licenziya-taksi/`,
  serviceId: `1`,
}

export default ServiceCard

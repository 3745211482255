import React from "react"
import { StaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import ModalCallback from "../components/Global/ModalCallback"

export default function CallMeBack({lang, slug, title, price}) {
  return (
    <StaticQuery
      query={graphql`
        {
          file(relativePath: { eq: "call.jpeg" }) {
            relativePath
            id
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => (
        <BackgroundImage fluid={data.file.childImageSharp.fluid}>
          <section className="calltoaction text-white py-5">
            <Container className="container mt-5 mb-0 mb-md-5">
              <div className="title2 text-uppercase pb-5">
                {lang === "ru"
                  ? `Получите консультацию`
                  : `Отримайте консультацію`}
              </div>
              <Row className="h-100">
                <div className="col-md my-auto">
                  <p>
                    {lang === "ru" ? `Нужны ответы?` : `Маєте запитання?`}
                    <br />
                    {lang === "ru"
                      ? `Звоните, чтобы получить консультацию специалиста `
                      : `Отримайте професійну відповідь фахівця `}
                    {lang === "ru"
                      ? `или закажите обратный звонок!`
                      : `або замовте зворотний дзвінок!`}
                  </p>
                </div>
                <div className="col-md text-center my-auto py-4">
                  <ModalCallback
                    buttonVariant={`danger`}
                    buttonText={
                      lang === "ru" ? `Нужно перезвонить?` : `Замовити дзвінок`
                    }
                    buttonClass={`callback-btn`}
                    slug={slug}
                    title={title}
                    price={price}
                  />
                  <p className="pt-3">
                    {lang === "ru"
                      ? `звоним с 10:00 до 18:00`
                      : `телефонуємо з 10:00 по 18:00`}
                  </p>
                </div>
              </Row>
            </Container>
          </section>
        </BackgroundImage>
      )}
    />
  )
}
